import React, { useState, useEffect } from 'react';
import {Form, Col, Row, Container, Button, Card} from 'react-bootstrap'

const AddOption = ({show, showAddForm, item, handleItemChange, saveEditItem, cancelEditItem, isDisabled, handleOnUploadClick, handleOnOverlayUploadClick, handleUploadItemChange, handleUploadOverlayItemChange}) => {
  const [validated, setValidated] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    saveEditItem();
  };

  const cancelAdd =(e) => {
    setValidated(false);
    cancelEditItem();
  }

  return (
    <>
    { 
      show &&
      <Container>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {!showAddForm &&
            <Form.Group as={Row} >
              <Form.Label column sm={2}>
                ID
              </Form.Label>
              <Col sm={10}>
                <Form.Control 
                  data-property="id" 
                  type="text" 
                  placeholder="" 
                  value={item['id']} 
                  readOnly={true}
                  />
              </Col>
            </Form.Group>
          }
          
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Kutesmart Code
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'kutesmartCode'}
                type="text"
                placeholder="" 
                value={item['kutesmartCode']} 
                onChange={handleItemChange} 
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Item Type
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'itemType'}
                type="text"
                placeholder="" 
                value={item['itemType']} 
                readOnly={true}
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Section
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'sectionDesc'}
                type="text"
                placeholder=""
                value={item['sectionDesc']} 
                readOnly={true}
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Option Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property="optionDesc" 
                type="text" 
                placeholder="Please enter name" 
                value={item['optionDesc']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter Name before submission</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Col sm={6}>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Option Image URL
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="text" 
                    placeholder="Option Image URL" 
                    value={item['optionImageUrl']} 
                    readOnly={true}
                    />
                </Col>
              </Form.Group>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Upload Image
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    data-property="optionImageUrl" 
                    type="file" 
                    placeholder="Please enter name" 
                    // value={item['optionImageUrl']} 
                    onChange={handleUploadItemChange} 
                    />
                  <Form.Control.Feedback type="invalid">Please enter URL before submission</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                </Form.Label>
                <Col sm={8}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={item['filePreview'] ? item['filePreview'] : item['optionImageUrl']} />
                    <Card.Body>
                      <Card.Title>Image Preview</Card.Title>
                      <Card.Text></Card.Text>
                      <Button onClick={handleOnUploadClick} disabled={!item['filePreview']}>Upload</Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Form.Group>
            
            </Col>
            <Col  sm={6}>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Overlay Image URL
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    type="text" 
                    placeholder="Overlay Image URL" 
                    value={item['overlayImageUrl']} 
                    readOnly={true}
                    />
                </Col>
              </Form.Group>
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  Upload Overlay Image
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    data-property="overlayImageUrl" 
                    type="file" 
                    placeholder="Please enter name" 
                    // value={item['overlayImageUrl']} 
                    onChange={handleUploadOverlayItemChange} 
                    />
                  <Form.Control.Feedback type="invalid">Please enter URL before submission</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                </Form.Label>
                <Col sm={8}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={item['overlayPreview'] ? item['overlayPreview'] : item['overlayImageUrl']} />
                    <Card.Body>
                      <Card.Title>Image Preview</Card.Title>
                      <Card.Text></Card.Text>
                      <Button onClick={handleOnOverlayUploadClick} disabled={!item['overlayPreview']}>Upload</Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Form.Group>
            </Col>
          </Form.Group>


          

          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Option Price
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property="optionPrice" 
                type="text" 
                placeholder="Please enter price" 
                value={item['optionPrice']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter URL before submission</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Option Sequence
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property={'sequence'}
                type="text"
                placeholder="" 
                value={item['sequence']} 
                onChange={handleItemChange} 
              >
                  
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Button className="mr-1 mb-3" type="submit" disabled={isDisabled}>Save</Button>
          {cancelEditItem && <Button className="mr-1 mb-3" variant="danger" onClick={cancelAdd}>Cancel</Button>}
        </Form>
        {/* <div><pre>{JSON.stringify(item, null, 2) }</pre></div> */}

      </Container>
    }
    </>
  )
}

export default AddOption