import React, {useState, useEffect} from 'react';
import {Table, Container, Form, Toast, Button, Row, Col} from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo";
import Loader from '../components/loading';
import GenericPagination from '../components/genericPagination';
import AddOption from '../components/design/addOption';
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';

const DesignOptionsSummary = () => {
  const emptyEditItem = {
    id: '',
    sectionId: '',
    optionDesc: '',
    optionImageUrl: '',
    overlayImageUrl: '',
    filePreview: null,
    optionIdentifier:'',
    optionPrice:0,
    kutesmartCode: '',
  }
  const [options, setOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [selectedFile, setSelectedFile] = useState({})
  const [selectedOverlayFile, setSelectedOverlayFile] = useState({})
  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [itemType, setItemType] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const [showAddOption, setShowAddOption] = useState(false);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')

  const getSectionAndOptions = () => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/design-options`
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if (itemType) {
      queryParams.push(`itemType=${itemType}`)
    }
    if (sectionId) {
      queryParams.push(`sectionId=${sectionId}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    Fetch.get(`${url}${queryString}`).then(data => {
      setOptions(data.data);
      setPagination(data.pagination);
    });
  }

  const getSectionsByItemType = (itemType) => {
    const url=`${constants.BACKEND_URL}/design-sections/${itemType}`
    Fetch.get(url).then(data => {
     setSections(data.sections)
    })

  }

  const getSection = (sectionId) => {
    let obj = {}
    if (sections) {
      obj = sections.find(section => {
        return Number(section.id) === Number(sectionId)
      })
      
    }
    return obj;
  }
  
  useEffect(() => {
    getSectionAndOptions()
    return () => {
      
    }
  }, [itemType, sectionId, page])

  const handleOnItemTypeChange = (e) => {
    setItemType(e.target.value)
    setSectionId('');
    getSectionsByItemType(e.target.value);
    setPage(1);
  }

  const handleOnSectionChange = (e) => {
    setSectionId(e.target.value);
  }

  const handleOnEditOptionClick = (e) => {
    const obj = JSON.parse(e.target.dataset.value);
    setEditItem(obj)
    setIsEditing(true);
    setShowAddOption(true);
    setShowSummaryTable(false);
  }

  const handleOnAddOptionClick = (e) => {
    const section = getSection(sectionId);
    emptyEditItem.sectionDesc = section.sectionDesc;
    emptyEditItem.sectionId = sectionId;
    emptyEditItem.itemType = itemType;
    setIsEditing(false);
    setEditItem(emptyEditItem);
    setShowAddOption(true);
    setShowSummaryTable(false);
  }

  const handleItemChange = (e) => {
    const changedProperty = {}
    changedProperty[e.target.dataset.property] = e.target.value;
    setEditItem({...editItem, ...changedProperty});
  }

  const handleUploadItemChange = (e) => {
    e.persist();
    const changedProperty = {}
    if (e.target.files && e.target.files[0]) {
      changedProperty['filePreview'] = window.URL.createObjectURL(
        new Blob([e.target.files[0]], {
          type: "image/png",
        })
      )
      setSelectedFile(e.target.files[0]);
    }
    setEditItem({...editItem, ...changedProperty});
  }

  const handleUploadOverlayItemChange = (e) => {
    e.persist();
    const changedProperty = {}
    if (e.target.files && e.target.files[0]) {
      changedProperty['overlayPreview'] = window.URL.createObjectURL(
        new Blob([e.target.files[0]], {
          type: "image/png",
        })
      )
      setSelectedOverlayFile(e.target.files[0]);
    }
    setEditItem({...editItem, ...changedProperty});
  }
  
  const handleOnUploadClick = (e) => {
    setShowLoader(true)
    const data = new FormData() 
    data.append('file', selectedFile)
    const url=`${constants.BACKEND_URL}/design-options/upload/${editItem.id}`
    Fetch.post(url, data, true).then(res => {
      if(res.error) {
        setShowLoader(false);
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      if (res.data) {
        const imageLocation = {optionImageUrl: res.data};
        setEditItem({...editItem, ...imageLocation});
        setShowLoader(false);
      }
     
    })
  }

  const handleOnOverlayUploadClick = (e) => {
    setShowLoader(true)
    const data = new FormData() 
    data.append('file', selectedOverlayFile)
    const url=`${constants.BACKEND_URL}/design-options/upload/overlay/${editItem.id}`
    Fetch.post(url, data, true).then(res => {
      if(res.error) {
        setShowLoader(false);
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      if (res.data) {
        const imageLocation = {overlayImageUrl: res.data};
        setEditItem({...editItem, ...imageLocation});
        setShowLoader(false);
      }
     
    })
  }
  
  const saveEditItem = () => {
    setShowLoader(true)
    const url = `${constants.BACKEND_URL}/design-options`
    if(!isEditing) {
      Fetch.post(url, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowLoader(false);
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        if (res.data) {
          setOptions(res.data)
          setShowSummaryTable(true);
          setShowAddOption(false);
          setShowToast(false);
        }
        getSectionAndOptions();
        setIsEditing(false);
        setShowAddOption(false);
        setShowSummaryTable(true);
        setShowLoader(false);
        
      })
    } else {
      Fetch.put(`${url}/${editItem.id}`, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowLoader(false);
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        if(!res.status) {
          setShowToast(true);
          setShowLoader(false);
          seterrorMessage(res.msg || res.error);
          return;
        }
        getSectionAndOptions();
        setShowToast(false);
        setIsEditing(false);
        setShowAddOption(false);
        setShowSummaryTable(true);
        setShowLoader(false);
      })
    }
    
  }

  const cancelEditItem = (e) => {
    setShowSummaryTable(true);
    setShowAddOption(false);
    setShowEditOption(false);
  }

  const handleOnDeleteOptionClick = (e) => {
    const deleteId = e.target.dataset.deleteId;
    const url=`${constants.BACKEND_URL}/design-options/${deleteId}`
    setShowLoader(true);
    Fetch.delete(url).then(data => {
      getSectionAndOptions();
      setShowLoader(false);
    })
  }
  
  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleSetDefaultClick = (e) => {
    const {sectionId, optionId} = e.target.dataset;
    const url=`${constants.BACKEND_URL}/design-options/default/${sectionId}/${optionId}`
    const data={}
    Fetch.put(url, JSON.stringify(data)).then(res => {
     getSectionAndOptions();
    })
  }

  return (
    <Layout pageInfo={{ pageName: "DesignOptionsSummary" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Design Options" />
      <Container className="text-center" >
        <Row>
          <Col className="text-center">
            <h5>Design Options Summary</h5>
          </Col>
        </Row>
        { showSummaryTable && 
          <>
            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Item Type</Form.Label>
                  <Col>
                    <Form.Control as="select" 
                      value={itemType}
                      onChange={handleOnItemTypeChange}
                    >
                      <option value=''>--Select Status--</option>
                      {
                        constants.ITEM_TYPES.map(x => {
                          return (
                            <option key={x} >{x}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Design Section</Form.Label>
                  <Col>
                    <Form.Control as="select" 
                      value={sectionId}
                      onChange={handleOnSectionChange}
                    >
                      <option value=''>--Select Status--</option>
                      {
                        sections.map(x => {
                          return (
                            <option key={x.id} value={x.id}>{x.sectionDesc}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              {
                sectionId && 
                <Col lg={2} md="4" xs="6" className="text-left">
                  <Button onClick={handleOnAddOptionClick}>Add Option</Button>
                </Col>
              }
            </Row>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Default</th>
                      <th>Kutesmart Code</th>
                      <th>Item Type</th>
                      <th>Section</th>
                      <th>Option</th>
                      <th>Identifier</th>
                      <th>Image URL</th>
                      <th>Overlay URL</th>
                      <th>Price</th>
                      <th>Sequence</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {options && Array.isArray(options) ? 
                      options.map(item => {
                        return (
                          <tr key={item.id}>
                            <td><span 
                              className={`oi oi-star ${item.defaultOption ? 'text-warning' : ' pointer text-muted'}`} 
                              onClick={handleSetDefaultClick} 
                              data-section-id={sectionId} 
                              data-option-id={item.id}
                              disabled={item.defaultOption ? 'true' : 'false'}>
                              </span>
                            </td>
                            <td>{item.kutesmartCode}</td>
                            <td>{item.itemType}</td>
                            <td>{item.sectionDesc}</td>
                            <td>{item.optionDesc}</td>
                            <td>{item.optionIdentifier}</td>
                            <td>{item.optionImageUrl ? (<img style={{width:`5rem`}} src={item.optionImageUrl}/>) : <></>}</td>
                            <td>{item.overlayImageUrl ? (<img style={{width:`5rem`}} src={item.overlayImageUrl}/>) : <></>}</td>
                            <td>{item.optionPrice}</td>
                            <td>{item.sequence}</td>
                            <td>
                              <Button className="mr-2" data-value={JSON.stringify(item)} onClick={handleOnEditOptionClick}>Edit</Button>
                              <Button variant="danger" data-delete-id={item.id} onClick={handleOnDeleteOptionClick}>Delete</Button>
                            </td>
                          </tr>
                        )
                      }) : ''
                    }
                  </tbody>
                </Table>  
              </Col>
            </Row>
          </>
        }
        <AddOption 
          show={showAddOption} 
          item={editItem} 
          handleItemChange={handleItemChange}
          handleOnUploadClick={handleOnUploadClick}
          handleOnOverlayUploadClick={handleOnOverlayUploadClick}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
          handleUploadItemChange={handleUploadItemChange}
          handleUploadOverlayItemChange={handleUploadOverlayItemChange}
        ></AddOption>
        <Toast 
          show={showToast} 
          onClose={() => {setShowToast(false)}}
          autohide
          style={{
            position: 'absolute',
            top: '1vh',
            'minHeight': '2vh',
            right: 0,
          }}
        >
          
            <Toast.Body><span className="oi oi-warning text-danger"></span><strong className="mr-auto text-danger">{errorMessage}</strong></Toast.Body>
        </Toast>
        {pagination && showSummaryTable ? 
            <GenericPagination 
              pagination={pagination} 
              rowLimit={rowLimit}
              handleOnPaginationClick={handleOnPaginationClick}
            ></GenericPagination> : <></>}
        </Container>
    </Layout>
  )
}

export default DesignOptionsSummary